import React, { useState } from 'react';
import { Button, TextField, Typography, Box, Stack, ToggleButton, ToggleButtonGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Link } from 'react-router-dom';

const Volumes = () => {
  const [height, setHeight] = useState('');
  const [gender, setGender] = useState('Male');
  const [unit, setUnit] = useState('inches');
  const [ibwResult, setIbwResult] = useState('');
  const [tidalVolumes, setTidalVolumes] = useState([]); // Updated to store the list of tidal volumes
  const [minuteVentilation, setMinuteVentilation] = useState('');

  // Handle gender change
  const handleGenderChange = (event, newGender) => {
    if (newGender !== null) {
      setGender(newGender);
    }
  };

  // Handle unit change
  const handleUnitChange = (event, newUnit) => {
    if (newUnit !== null) {
      setUnit(newUnit);
    }
  };

  // Calculate IBW, Tidal Volume, and Minute Ventilation
  const calculateIBWAndTidalVolume = () => {
    let heightValue = parseFloat(height);
    if (!heightValue || heightValue <= 0) {
      setIbwResult('Please enter a valid height.');
      return;
    }

    // Convert height to inches if it's in cm
    if (unit === 'cm') {
      heightValue = heightValue / 2.54;
    }

    // Calculate IBW and round it
    let ibw = gender === 'Male' ? 50 + 2.3 * (heightValue - 60) : 45.5 + 2.3 * (heightValue - 60);
    ibw = Math.ceil(ibw);

    // Calculate tidal volumes for 6, 7, and 8 mL/kg and store in a list
    const volumes = [
      { name: '6 mL/kg', value: Math.ceil(ibw * 6 / 10) * 10 },
      { name: '7 mL/kg', value: Math.ceil(ibw * 7 / 10) * 10 },
      { name: '8 mL/kg', value: Math.ceil(ibw * 8 / 10) * 10 },
    ];

    // Calculate Minute Ventilation
    const avgVentilation = (ibw * 100) / 1000;

    // Update state with calculated results
    setIbwResult(`Ideal Body Weight: ${ibw} kg`);
    setTidalVolumes(volumes);
    setMinuteVentilation(`Minute Ventilation: ${avgVentilation.toFixed(1)} L/min`);
  };

  return (
    <Box sx={{ textAlign: 'center', marginTop: 4, paddingBottom: 8 }}>
      <Typography variant="h4" gutterBottom>
        Volumes
      </Typography>

      {/* Description */}
      <Typography 
        variant="body1" 
        gutterBottom 
        sx={{ color: 'rgba(0, 0, 0, 0.6)', marginTop: 3, marginBottom: 3 }}
      >
        Enter the patient's height to estimate IBW and calculate a target TV based on IBW. It will also return an estimated initial V̇, also based on IBW.
      </Typography>

      <Box sx={{ marginBottom: 3, maxWidth: 400, margin: '0 auto'}}>
        {/* Height input */}
        <TextField
          label={`Height (${unit === 'inches' ? 'inches' : 'cm'})`}
          value={height}
          onChange={(e) => setHeight(e.target.value)}
          variant="outlined"
          fullWidth
          sx={{ marginBottom: 2 }}
        />

        {/* Unit selection */}
        <Typography variant="body1">Select Height Unit:</Typography>
        <ToggleButtonGroup
          value={unit}
          exclusive
          onChange={handleUnitChange}
          aria-label="Height unit"
          fullWidth
          sx={{ marginBottom: 2 }}
        >
          <ToggleButton value="inches" aria-label="Inches">
            Inches
          </ToggleButton>
          <ToggleButton value="cm" aria-label="Centimeters">
            Centimeters
          </ToggleButton>
        </ToggleButtonGroup>

        {/* Gender selection */}
        <Typography variant="body1">Select Gender:</Typography>
        <ToggleButtonGroup
          value={gender}
          exclusive
          onChange={handleGenderChange}
          aria-label="Gender"
          fullWidth
        >
          <ToggleButton value="Male" aria-label="Male">
            Male
          </ToggleButton>
          <ToggleButton value="Female" aria-label="Female">
            Female
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {/* Calculate button */}
      <Button 
        variant="contained" 
        color="primary" 
        onClick={calculateIBWAndTidalVolume}
        sx={{ marginTop: 4 }}
      >
        Calculate
      </Button>

      {/* Display Results */}
      <Box sx={{ marginTop: 3 }}>
        <Typography variant="body1" gutterBottom>
          <strong>{ibwResult}</strong>
        </Typography>

        {/* Tidal Volumes Table */}
        {tidalVolumes.length > 0 && (
          <TableContainer component={Paper} sx={{ maxWidth: 300, margin: '0 auto' }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell><strong>mL/kg of IBW</strong></TableCell>
                  <TableCell align="right"><strong>Tidal Volume (mL)</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tidalVolumes.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell align="right">{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {/* Minute Ventilation Result */}
        <Typography variant="body1" gutterBottom sx={{ marginTop: 2 }}>
          <strong>{minuteVentilation}</strong>
        </Typography>
      </Box>

      {/* Navigation Buttons */}
      <Stack direction="row" spacing={2} justifyContent="center" sx={{ marginTop: 5 }}>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/rate"
          sx={{
            minHeight: '70px',  // Adjust height to accommodate two lines of text
            whiteSpace: 'normal',  // Allow text to wrap
            padding: '8px 16px',  // Adjust padding for consistency
          }}
        >
          Next: Rate
        </Button>
      </Stack>

      {/* Back to Home Button */}
      <Box sx={{ marginTop: 3 }}>
        <Button
          variant="contained"
          color="secondary"
          component={Link}
          to="/"
          sx={{
            padding: '8px 16px',
          }}
        >
          Back to Home
        </Button>
      </Box>
    </Box>
  );
};

export default Volumes;
