import React, { useState } from 'react';
import { Button, TextField, Typography, Box, Stack } from '@mui/material';
import { Link } from 'react-router-dom';

// Reusable input field component to avoid duplication
const InputField = ({ label, value, onChange }) => (
  <TextField
    label={label}
    value={value}
    onChange={onChange}
    variant="outlined"
    fullWidth
    sx={{ marginBottom: 3 }} // Consistent margin for all input fields
  />
);

const Rate = () => {
  const [tidalVolume, setTidalVolume] = useState('');
  const [minuteVentilation, setMinuteVentilation] = useState('');
  const [rrResult, setRrResult] = useState('');

  // Function to calculate the respiratory rate
  const calculateRR = () => {
    const tidalVolumeValue = parseFloat(tidalVolume);
    const minuteVentilationValue = parseFloat(minuteVentilation);

    // Basic validation to check valid inputs
    if (!tidalVolumeValue || !minuteVentilationValue || tidalVolumeValue <= 0 || minuteVentilationValue <= 0) {
      setRrResult('Please enter valid values.');
      return;
    }

    // Calculate respiratory rate and round it
    const rr = (minuteVentilationValue * 1000) / tidalVolumeValue;
    setRrResult(`Respiratory Rate: ${Math.round(rr)} breaths/min`);
  };

  return (
    <Box sx={{ textAlign: 'center', marginTop: 4, paddingX: 2, paddingBottom: 8 }}>
      <Typography variant="h4" gutterBottom>
        Rate
      </Typography>

      {/* Instructional caption */}
      <Typography
        variant="body1"
        gutterBottom
        sx={{ color: 'rgba(0, 0, 0, 0.6)', marginTop: 3, marginBottom: 3 }}
      >
        Enter the desired TV and V̇ to calculate the required RR.
      </Typography>

      {/* Input fields for Tidal Volume and Minute Ventilation */}
      <Box sx={{ marginBottom: 3, maxWidth: 400, marginX: 'auto' }}>
        <InputField
          label="Tidal Volume (mL)"
          value={tidalVolume}
          onChange={(e) => setTidalVolume(e.target.value)}
        />
        <InputField
          label="Minute Ventilation (L/min)"
          value={minuteVentilation}
          onChange={(e) => setMinuteVentilation(e.target.value)}
        />
      </Box>

      {/* Calculate button */}
      <Button 
        variant="contained" 
        color="primary" 
        onClick={calculateRR}
        sx={{ marginTop: 3 }}
      >
        Calculate
      </Button>

      {/* Display the calculated respiratory rate */}
      <Box sx={{ marginTop: 3 }}>
        <Typography variant="body1" gutterBottom>
          <strong>{rrResult}</strong>
        </Typography>
      </Box>

       {/* Navigation buttons */}
       <Stack direction="row" spacing={2} justifyContent="center" sx={{ marginTop: 5 }}>
        <Button
          variant="contained"
          color="secondary"
          component={Link}
          to="/volumes"
          sx={{
            minHeight: '70px',  // Adjust height to accommodate two lines of text
            whiteSpace: 'normal',  // Allow text to wrap
            padding: '8px 16px',  // Adjust padding for consistency
          }}
        >
          Prev: Volumes
        </Button>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/winters-formula"
          sx={{
            minHeight: '70px',  // Adjust height to accommodate two lines of text
            whiteSpace: 'normal',  // Allow text to wrap
            padding: '8px 16px',  // Adjust padding for consistency
          }}
        >
          Next: Winter's Formula
        </Button>
      </Stack>

      {/* Back to home button */}
      <Box sx={{ marginTop: 3 }}>
        <Button variant="contained" color="secondary" component={Link} to="/" sx={{ padding: '8px 16px' }}>
          Back to Home
        </Button>
      </Box>
    </Box>
  );
};

export default Rate;
