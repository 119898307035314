import React, { useState } from 'react';
import { Button, TextField, Typography, Box, Stack } from '@mui/material';
import { Link } from 'react-router-dom';

// Reusable InputField component for handling text inputs
const InputField = ({ label, value, onChange, error, helperText }) => (
  <TextField
    label={label}
    value={value}
    onChange={onChange}
    variant="outlined"
    fullWidth
    error={error}
    helperText={helperText}
    sx={{ marginBottom: 4 }} // Consistent margin for all input fields
  />
);

const WintersFormula = () => {
  const [hco3, setHco3] = useState(''); // Track input value for HCO₃
  const [result, setResult] = useState(''); // Store the result for the calculation
  const [error, setError] = useState(false); // Track input validation

  // Function to calculate Winter's Formula
  const calculateWintersFormula = () => {
    const hco3Value = parseFloat(hco3); // Parse the input value as a number

    // Validate input - ensure it's a positive number
    if (isNaN(hco3Value) || hco3Value <= 0) {
      setResult('Please enter a valid HCO₃ value.');
      setError(true);
      return;
    }

    // Clear error if validation passes
    setError(false);

    // Calculate Winter's Formula (Expected PaCO₂)
    const wintersResult = (1.5 * hco3Value) + 8;
    const minPaCO2 = Math.round(wintersResult - 2);
    const maxPaCO2 = Math.round(wintersResult + 2);

    // Set the result state with the calculated range
    setResult(`Expected PaCO₂: ${minPaCO2} to ${maxPaCO2} mmHg`);
  };

  return (
    <Box sx={{ textAlign: 'center', marginTop: 4, paddingBottom: 8 }}>
      {/* Title */}
      <Typography variant="h4" gutterBottom>
        Winter's Formula
      </Typography>

      {/* Caption */}
      <Typography 
        variant="body1" 
        gutterBottom 
        sx={{ color: 'rgba(0, 0, 0, 0.6)', marginTop: 3, marginBottom: 3 }}
      >
        Enter the HCO₃ level to calculate the expected PaCO₂ range.
      </Typography>

      {/* Input Field for HCO₃ */}
      <Box sx={{ marginBottom: 4, maxWidth: 400, margin: '0 auto' }}>
        <InputField 
          label="Serum Bicarbonate (mEq/L)" 
          value={hco3} 
          onChange={(e) => setHco3(e.target.value)} 
          error={error}
          helperText={error ? 'Please enter a valid number greater than 0.' : ''}
        />
      </Box>

      {/* Calculate Button */}
      <Button 
        variant="contained" 
        color="primary" 
        onClick={calculateWintersFormula}
        sx={{ marginTop: 4 }}
      >
        Calculate
      </Button>

      {/* Result Display */}
      <Box sx={{ marginTop: 4 }}>
        <Typography variant="body1" gutterBottom>
          <strong>{result}</strong>
        </Typography>
      </Box>

           {/* Navigation Buttons */}
           <Stack direction="row" spacing={2} justifyContent="center" sx={{ marginTop: 5 }}>
        <Button
          variant="contained"
          color="secondary"
          component={Link}
          to="/rate"
          sx={{
            minHeight: '70px',  // Adjust height to accommodate two lines of text
            whiteSpace: 'normal',  // Allow text to wrap
            padding: '8px 16px',  // Adjust padding for consistency
          }}
        >
          Prev: Rate
        </Button>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/paco2-correction"
          sx={{
            minHeight: '70px',  // Adjust height to accommodate two lines of text
            whiteSpace: 'normal',  // Allow text to wrap
            padding: '8px 16px',  // Adjust padding for consistency
          }}
        >
          Next: PaCO₂ Correction
        </Button>
      </Stack>

      {/* Back to Home Button */}
      <Box sx={{ marginTop: 3 }}>
        <Button
          variant="contained"
          color="secondary"
          component={Link}
          to="/"
          sx={{ padding: '8px 16px' }}
        >
          Back to Home
        </Button>
      </Box>
    </Box>
  );
};

export default WintersFormula;
