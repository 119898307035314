import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container, AppBar, Toolbar, Typography } from '@mui/material';
import Home from './components/Home';
import Volumes from './components/Volumes';  // Renamed from VolumesCalculator
import Rate from './components/Rate';  // Renamed from RateCalculator
import WintersFormula from './components/WintersFormula';  // Renamed from WintersFormula
import PaCo2Correction from './components/PaCo2Correction';  // Renamed from PaCO2Correction
import References from './components/References';
import About from './components/About';
import TermsOfService from './components/TermsOfService';

const App = () => {
  return (
    <Router>
      <Container maxWidth="md">
        {/* AppBar for consistent navigation and branding */}
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Vent Calc
            </Typography>
          </Toolbar>
        </AppBar>

        {/* Routes to different pages */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/volumes" element={<Volumes />} />
          <Route path="/rate" element={<Rate />} />
          <Route path="/winters-formula" element={<WintersFormula />} />
          <Route path="/paco2-correction" element={<PaCo2Correction />} />
          <Route path="/references" element={<References />} />
          <Route path="/about" element={<About />} />
          <Route path="/terms" element={<TermsOfService />} />
        </Routes>
      </Container>
    </Router>
  );
};

export default App;