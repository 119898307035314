import React from 'react';
import { Button, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const TermsOfService = () => {
  return (
    <Box sx={{ textAlign: 'center', marginTop: 4, paddingBottom: 8 }}>
      <Typography variant="h4" gutterBottom>
        Terms of Service
      </Typography>

      <Box sx={{ textAlign: 'left', marginX: 3, marginBottom: 4 }}>
        <Typography variant="body1" gutterBottom sx={{ marginTop: 2 }}>
          <strong>Last Updated:</strong> October 2, 2024
        </Typography>

        <Typography variant="body1" gutterBottom sx={{ marginTop: 2 }}>
          <strong>1. Acceptance of Terms</strong><br/>
          By downloading, accessing, or using the Vent Calc App ("the App"), you agree to be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, you may not use the App.
        </Typography>

        <Typography variant="body1" gutterBottom>
          <strong>2. Purpose of the App</strong><br/>
          The App is intended to provide ventilator setting calculations and related information. It is designed to assist healthcare professionals but is not a substitute for professional clinical judgment or individualized patient care. The information provided by the App may not always be accurate or complete, and you are responsible for verifying any recommendations and calculations with other sources.
        </Typography>

        <Typography variant="body1" gutterBottom>
          <strong>3. No Medical Advice</strong><br/>
          The App does not provide medical advice. The content provided in the App is for informational purposes only. Always seek advice from a qualified healthcare provider with any questions related to a patient's medical condition or treatment. Use of the App does not establish a patient-provider relationship.
        </Typography>

        <Typography variant="body1" gutterBottom>
          <strong>4. Limitation of Liability</strong><br/>
          Red Roof Studios, the developer of the App, assumes no liability for any harm, loss, or damages resulting from the use of the App. You acknowledge that the use of the App is at your sole risk. In no event shall Red Roof Studios or any of its affiliates, employees, or partners be liable for any direct, indirect, incidental, or consequential damages related to the use of the App.
        </Typography>

        <Typography variant="body1" gutterBottom>
          <strong>5. User Responsibilities</strong><br/>
          Users of the App are responsible for:
          <ul>
            <li>Understanding and interpreting the calculations provided by the App.</li>
            <li>Ensuring that the App is used only as a supplementary tool and not for critical medical decisions.</li>
            <li>Consulting with other medical professionals or tools to corroborate the App's information.</li>
          </ul>
        </Typography>

        <Typography variant="body1" gutterBottom>
          <strong>6. Data Collection and Privacy</strong><br/>
          We do not request, collect, or use any personal or medical information entered into the App. However, while we do not collect personal data, we may collect non-personal information regarding how the App is used. This information may include general usage statistics and device information. Such data is collected solely to improve the functionality and user experience of the App and does not identify individual users. For more details, please refer to our Privacy Policy.
        </Typography>

        <Typography variant="body1" gutterBottom>
          <strong>7. Intellectual Property</strong><br/>
          All intellectual property rights related to the App, including its design, code, and content, are the exclusive property of Red Roof Studios. You may not reproduce, distribute, or create derivative works from the App without prior written permission.
        </Typography>

        <Typography variant="body1" gutterBottom>
          <strong>8. App Modifications</strong><br/>
          Red Roof Studios reserves the right to modify or discontinue the App at any time without notice. Any changes to these Terms will be effective upon posting on the App or website. Your continued use of the App after any such changes constitutes acceptance of the revised Terms.
        </Typography>

        <Typography variant="body1" gutterBottom>
          <strong>9. Privacy</strong><br/>
          Vent Calc does not request, collect, or use any personal or medical information. However, non-personal information regarding general app usage and device details may be collected to help improve the app's performance and user experience. This data is anonymous and does not identify individual users. For more detailed information, please review our full Privacy Policy.
        </Typography>

        <Typography variant="body1" gutterBottom>
          <strong>10. Governing Law</strong><br/>
          These Terms shall be governed by and construed in accordance with the laws of the state of Florida, USA, without regard to its conflict of law principles.
        </Typography>

        <Typography variant="body1" gutterBottom>
          <strong>11. Contact Information</strong><br/>
          If you have any questions or concerns about these Terms, please contact us at:<br/>
          Email: ventcalc.rrs@gmail.com<br/>
          Developer: Red Roof Studios
        </Typography>
      </Box>

      {/* Return to Home button with extra margin-bottom */}
      <Box sx={{ marginTop: 4, marginBottom: 0 }}>  {/* Adding marginBottom to add space between the button and the page end */}
        <Button variant="contained" color="secondary" component={Link} to="/">
          Return to Home
        </Button>
      </Box>
    </Box>
  );
};

export default TermsOfService;
