import React, { useState } from 'react';
import { Button, TextField, Typography, Box, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Link } from 'react-router-dom';

// Reusable InputField component for cleaner and more maintainable code
const InputField = ({ label, value, onChange }) => (
  <TextField
    label={label}
    value={value}
    onChange={onChange}
    variant="outlined"
    fullWidth
    sx={{ marginBottom: 3 }}
  />
);

const PaCO2Correction = () => {
  const [currentCo2, setCurrentCo2] = useState(''); // State for current PaCO₂
  const [desiredCo2, setDesiredCo2] = useState(''); // State for desired PaCO₂
  const [currentRR, setCurrentRR] = useState(''); // State for respiratory rate
  const [currentMinuteVentilation, setCurrentMinuteVentilation] = useState(''); // State for minute ventilation
  const [correctedValue, setCorrectedValue] = useState(''); // Display the corrected value (either RR or Minute Ventilation)
  const [calculationMethod, setCalculationMethod] = useState('rate'); // Toggle between Rate and Minute Ventilation

  // Handle method toggle between rate and minute ventilation
  const handleCalculationMethodChange = (event, newMethod) => {
    if (newMethod !== null) {
      setCalculationMethod(newMethod);
    }
  };

  // Function to calculate the corrected value (either respiratory rate or minute ventilation)
  const calculateCorrectedValue = () => {
    const currentCo2Value = parseFloat(currentCo2);
    const desiredCo2Value = parseFloat(desiredCo2);

    // Basic validation
    if (!currentCo2Value || !desiredCo2Value || currentCo2Value <= 0 || desiredCo2Value <= 0) {
      setCorrectedValue('Please enter valid PaCO₂ values.');
      return;
    }

    let corrected;

    if (calculationMethod === 'rate') {
      const currentRRValue = parseFloat(currentRR);
      if (!currentRRValue || currentRRValue <= 0) {
        setCorrectedValue('Please enter a valid respiratory rate.');
        return;
      }
      corrected = (currentCo2Value * currentRRValue) / desiredCo2Value;
      setCorrectedValue(`New Respiratory Rate: ${Math.round(corrected)} breaths/min`);
    } else {
      const currentMinuteVentilationValue = parseFloat(currentMinuteVentilation);
      if (!currentMinuteVentilationValue || currentMinuteVentilationValue <= 0) {
        setCorrectedValue('Please enter a valid minute ventilation.');
        return;
      }
      corrected = (currentCo2Value * currentMinuteVentilationValue) / desiredCo2Value;
      setCorrectedValue(`New Minute Ventilation: ${Math.round(corrected)} L/min`);
    }
  };

  return (
    <Box sx={{ textAlign: 'center', marginTop: 4, paddingBottom: 8 }}>
      <Typography variant="h4" gutterBottom>
        PaCO₂ Correction
      </Typography>

      {/* Instructional caption */}
      <Typography 
        variant="body1" 
        gutterBottom 
        sx={{ color: 'rgba(0, 0, 0, 0.6)', marginTop: 3, marginBottom: 3 }}
      >
        Enter current and desired PaCO₂ levels, and select either the respiratory rate or minute ventilation to calculate the change required to achieve target PaCO₂. 
        For respiratory rate, this assumes no chane in the tidal volume. 
        If you want to change both respiratory rate and tidal volume, using minute ventilation is recommended.
      </Typography>

      <Box sx={{ marginBottom: 3, maxWidth: 400, marginX: 'auto' }}>
        {/* Input Fields for Current and Desired PaCO₂ */}
        <InputField 
          label="Current PaCO₂ (mmHg)" 
          value={currentCo2} 
          onChange={(e) => setCurrentCo2(e.target.value)} 
        />
        <InputField 
          label="Desired PaCO₂ (mmHg)" 
          value={desiredCo2} 
          onChange={(e) => setDesiredCo2(e.target.value)} 
        />

        {/* Calculation Method Toggle */}
        <Typography variant="body1">Select Calculation Method:</Typography>
        <ToggleButtonGroup
          value={calculationMethod}
          exclusive
          onChange={handleCalculationMethodChange}
          aria-label="Calculation Method"
          fullWidth
          sx={{ marginBottom: 3 }}
        >
          <ToggleButton value="rate" aria-label="Rate">
            Use Respiratory Rate
          </ToggleButton>
          <ToggleButton value="minuteVentilation" aria-label="Minute Ventilation">
            Use Minute Ventilation
          </ToggleButton>
        </ToggleButtonGroup>

        {/* Conditionally render fields based on calculation method */}
        {calculationMethod === 'rate' && (
          <InputField 
            label="Current Respiratory Rate (breaths/min)" 
            value={currentRR} 
            onChange={(e) => setCurrentRR(e.target.value)} 
          />
        )}

        {calculationMethod === 'minuteVentilation' && (
          <InputField 
            label="Current Minute Ventilation (L/min)" 
            value={currentMinuteVentilation} 
            onChange={(e) => setCurrentMinuteVentilation(e.target.value)} 
          />
        )}
      </Box>

      {/* Calculate Button */}
      <Button 
        variant="contained" 
        color="primary" 
        onClick={calculateCorrectedValue}
        sx={{ marginTop: 3 }}
      >
        Calculate
      </Button>

      {/* Result Display */}
      <Box sx={{ marginTop: 3 }}>
        <Typography variant="body1" gutterBottom>
          <strong>{correctedValue}</strong>
        </Typography>
      </Box>

      {/* Navigation Buttons */}
      <Stack direction="row" spacing={2} justifyContent="center" sx={{ marginTop: 5 }}>
        <Button
          variant="contained"
          color="secondary"
          component={Link}
          to="/winters-formula"
          sx={{
            minHeight: '70px',  // Adjust height to accommodate two lines of text
            whiteSpace: 'normal',  // Allow text to wrap
            padding: '8px 16px',  // Adjust padding for consistency
          }}
        >
          Prev: Winter's Formula
        </Button>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/references"
          sx={{
            minHeight: '70px',  // Adjust height to accommodate two lines of text
            whiteSpace: 'normal',  // Allow text to wrap
            padding: '8px 16px',  // Adjust padding for consistency
          }}
        >
          Next: Reference
        </Button>
      </Stack>

      {/* Back to Home Button */}
      <Box sx={{ marginTop: 3 }}>
        <Button
          variant="contained"
          color="secondary"
          component={Link}
          to="/"
          sx={{ padding: '8px 16px' }}
        >
          Back to Home
        </Button>
      </Box>
    </Box>
  );
};

export default PaCO2Correction;
