import React from 'react';
import { Button, Typography, Stack, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import MenuBookIcon from '@mui/icons-material/MenuBook';

// Reusable Button Component for cleaner code and easier updates
// It accepts `to`, `text`, `order`, and other props to provide flexibility for styles and routing
const CustomButton = ({ to, text, ...props }) => (
  <Button
    variant="contained"
    color="primary" // Use primary color from Material UI theme
    component={Link}
    to={to}
    sx={{
      padding: '18px 24px', // Increased padding for better click/tap targets
      textTransform: 'none', // Prevent text from being all caps
      fontSize: '1.1rem', // Slightly larger font size for better readability
      borderRadius: '12px', // Soft rounded corners for modern look
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
    }}
    {...props}
  >
    {text}
  </Button>
);

const Home = () => {
  return (
    <Box sx={{ textAlign: 'center', marginTop: 3.5, paddingBottom: 8 }}>
      
      {/* Section title */}
      <Typography variant="h5" gutterBottom sx={{ marginBottom: -1, fontWeight: 'bold' }}>
        Calculate:
      </Typography>

      {/* Calculation list */}
      <Box component="ul" sx={{ listStyleType: 'disc', textAlign: 'left', display: 'inline-block', marginBottom: 4, fontSize: '1.1rem' }}>
        <li>Ideal Body Weight (IBW)</li>
        <li>Tidal Volume (TV)</li>
        <li>Minute Ventilation (V̇)</li>
        <li>Respiratory Rate (RR)</li>
        <li>Winter’s Formula</li>
        <li>RR or V̇ to target a PaCO₂</li>
      </Box>

      {/* Action buttons with primary color */}
      <Stack spacing={4} sx={{ marginTop: 5, maxWidth: 600, margin: '0 auto' }}>
        <CustomButton to="/volumes" text="IBW and Volumes" />
        <CustomButton to="/rate" text="Rate" />
        <CustomButton to="/winters-formula" text="Winter's Formula" />
        <CustomButton to="/paco2-correction" text="PaCO₂ Correction" />
      </Stack>

      {/* Disclaimer */}
      <Typography variant="body2" color="error" sx={{ marginTop: 4 }}>
        <b><u>Disclaimer</u>:</b><br />
        The Vent Calc is provided as a tool for informational purposes only. It is not intended as a substitute for the expertise of a qualified physician or respiratory therapist. Always favor clinical judgment and verify results prior to direct use in patient care. Calculations may only be accurate for adults, not pediatric patients.<br />
      </Typography>

      {/* Footer buttons (References & About) */}
      <Stack direction="row" justifyContent="center" spacing={2} sx={{ marginTop: 4 }}>
        <Button
          variant="outlined"
          component={Link}
          to="/references"
          startIcon={<MenuBookIcon />}
          sx={{
            padding: '12px 24px',
            textTransform: 'none',
            borderColor: '#2196f3',
            '&:hover': { backgroundColor: 'rgba(33, 150, 243, 0.1)' },
          }}
        >
          Reference
        </Button>
        <Button
          variant="outlined"
          component={Link}
          to="/about"
          startIcon={<InfoIcon />}
          sx={{
            padding: '12px 24px',
            textTransform: 'none',
            borderColor: '#2196f3',
            '&:hover': { backgroundColor: 'rgba(33, 150, 243, 0.1)' },
          }}
        >
          About
        </Button>
      </Stack>

      <Typography variant="body2" sx={{ marginTop: 8 }}>
        <Link to="/terms">Terms of Service</Link>
      </Typography>
    </Box>
  );
};

export default Home;
