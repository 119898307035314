import React from 'react';
import { Button, Typography, Box, Divider, Stack } from '@mui/material';
import { Link } from 'react-router-dom';

// Reusable Section component for each reference section
const Section = ({ title, description, children }) => (
  <Box sx={{ marginBottom: 4 }}>
    <Typography variant="body1" gutterBottom>
      <strong>{title}</strong> {description}
    </Typography>
    {children}
  </Box>
);

const References = () => {
  return (
    <Box sx={{ textAlign: 'center', marginTop: 4, paddingBottom: 8 }}>
      <Typography variant="h4" gutterBottom>
        Reference
      </Typography>

      <Box sx={{ textAlign: 'left', marginX: 3, marginBottom: 4, marginTop: 4 }}>
        {/* Ideal Body Weight (IBW) Section */}
        <Section
          title="Ideal Body Weight (IBW):"
          description="An estimated weight based on height and gender, often used to calculate medication dosages and ventilator settings."
        >
          <ul>
            <li>For Males: IBW = 50 + 2.3 × (Height in inches - 60)</li>
            <li>For Females: IBW = 45.5 + 2.3 × (Height in inches - 60)</li>
          </ul>
        </Section>

        {/* Tidal Volume (TV) Section */}
        <Section
          title="Tidal Volume (TV):"
          description="The amount of air inhaled or exhaled in a single breath. This normally is calculated to remain in a range between 6-8 mL/kg. In certain conditions, it can be as low as 4 mL/kg and can go higher than 8 mL/kg."
        />

        {/* Minute Ventilation (V̇) Section */}
        <Section
          title="Minute Ventilation (V̇):"
          description="The total volume of air inhaled or exhaled per minute. On average, this is approximately 100 mL/kg/min. It may be higher in conditions of increased metabolic demand, acidosis, or if gas exchange is compromised."
        />

        {/* Respiratory Rate (RR) Section */}
        <Section
          title="Respiratory Rate (RR):"
          description="The number of breaths taken per minute."
        />

        {/* Winter's Formula Section */}
        <Section
          title="Winter's Formula:"
          description="A mathematical equation used to estimate the expected PaCO₂ level in a patient with metabolic acidosis."
        >
          <ul>
            <li>Expected PaCO₂ = 1.5 × [HCO₃−] + 8 ± 2</li>
          </ul>
        </Section>

        {/* Desired Respiratory Rate (RR) Section */}
        <Section
          title="Desired Respiratory Rate (RR):"
          description="Respiratory rate needed to achieve a target PaCO₂. In this calculator, it is used to correct hypercapnia, hypocapnia, or to compensate for a metabolic acidosis."
        >
          <ul>
            <li>RR = (Current RR × Current PaCO₂) / Desired PaCO₂</li>
          </ul>
        </Section>
      </Box>

      {/* Divider for separation */}
      <Divider sx={{ marginY: 4 }} />

      {/* Return to Home button */}
      <Box sx={{ marginTop: 4 }}>
        <Button variant="contained" color="secondary" component={Link} to="/">
          Return to Home
        </Button>
      </Box>
    </Box>
  );
};

export default References;
