import React from 'react';
import { Button, Typography, Box, Divider, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';

const About = () => {
  return (
    <Box sx={{ textAlign: 'center', marginTop: 4, paddingBottom: 8 }}>
      <Typography variant="h4" gutterBottom>
        About Vent Calc
      </Typography>

      <Box sx={{ textAlign: 'left', marginX: 3, marginBottom: 4, marginTop: 4 }}>
        <Typography variant="body1" gutterBottom>
          <strong>What is Vent Calc?</strong>
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ marginBottom: 4}}>
          Vent Calc is a comprehensive ventilation management tool designed to assist healthcare professionals in setting ventilator parameters efficiently.
        </Typography>

        <Typography variant="body1" gutterBottom>
          <strong>Why Use Vent Calc?</strong>
        </Typography>
          <ul>
            <li style={{ marginBottom: '18px' }}>
              <b>Streamlined Calculations:</b> Vent Calc automates complex calculations, saving healthcare professionals valuable time and reducing the risk of errors.
            </li>
            <li style={{ marginBottom: '18px' }}>
              <b>Structured Approach:</b> Vent Calc helps establish a more structured and consistent approach to ventilator setup, promoting standardization and reducing variability in patient care.
            </li>
            <li style={{ marginBottom: '18px' }}>
              <b>Supplementary Tool:</b> Vent Calc serves as a valuable resource to support clinical decision-making, complementing the expertise and judgment of healthcare professionals.
            </li>
          </ul>


        <Typography variant="body1" gutterBottom>
     
        </Typography>
      </Box>

      {/* Divider for separation */}
      <Divider sx={{ marginY: 4 }} />

      {/* Credits */}
      <Box sx={{ textAlign: 'center', marginTop: 4 }}>

        <Typography variant="body1" gutterBottom sx={{ fontSize: 12,marginTop: 0 }}>
          Developed by:
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ marginBottom: 3, color: 'onix'}}>
          jose
        </Typography>
      </Box>

      {/* Divider for separation */}
      <Divider sx={{ marginY: 0 }} />

      {/* Return to Home button with icon */}
      <Box sx={{ marginTop: 4 }}>
        <Button variant="contained" color="secondary" component={Link} to="/" startIcon={<HomeIcon />}>
          Return to Home
        </Button>
      </Box>

      {/* Feedback Link */}
      <Box sx={{ marginTop: 4 }}>
        <Typography variant="body2">
          Have feedback? <MuiLink href="mailto:ventcalc.rrs@gmail.com">Send us an email</MuiLink>.
        </Typography>
      </Box>
    </Box>
  );
};

export default About;
